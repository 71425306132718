import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je disperze druhů?" />
    <h1>Co je disperze druhů?</h1>
    <p><strong>Disperze</strong> (popř&iacute;padě<em> disperse</em>) je v ekologii a biogeografii pojem označuj&iacute; &scaron;&iacute;řen&iacute; druhů. Disperse je společně s <Link to="/biogeografie/evolucni/">evoluc&iacute;</Link> a <Link to="/biogeografie/evolucni/makroevoluce/#extinkce">extinkc&iacute;</Link> jeden ze tř&iacute; nejz&aacute;kladněj&scaron;&iacute;ch procesů biogeografie.</p>
    <p>Mezi z&aacute;kladn&iacute; pojmy disperze patř&iacute; vagilita a propagule. <strong>Vagilita </strong>je schopnost organismů se &scaron;&iacute;řit, ať už pasivně nebo aktivně. <strong>Propagule</strong> je jak&aacute;koliv forma organismu, kter&aacute; slouž&iacute; k jeho &scaron;&iacute;řen&iacute;, a to ať už semeno, larva, spora, oddenek či jin&eacute;.</p>
    <hr />
    <h2>Typy disperze</h2>
    <h3>Podle použit&iacute; vlastn&iacute; či extern&iacute; energie</h3>
    <p>Disperzi lze dělit podle použit&iacute; vlastn&iacute; nebo extern&iacute; energie na dva z&aacute;kladn&iacute; druhy:</p>
    <ul>
    <li><strong>aktivn&iacute; disperze</strong> (Použit&iacute; vlastn&iacute; energie, na del&scaron;&iacute; vzd&aacute;lenosti je energeticky velmi n&aacute;ročn&aacute;.)</li>
    <li><strong>pasivn&iacute; disperze</strong> (Použit&iacute; extern&iacute; energie, &scaron;&iacute;řen&iacute; za pomoci tzv. dispersn&iacute;ho m&eacute;dia, kter&yacute;m může b&yacute;t v&iacute;tr, voda, jin&eacute; organismy aj. Obvykle prob&iacute;h&aacute; u propagule, tedy v&yacute;vojov&eacute;ho st&aacute;dia organismu, kter&eacute; je k disperzi přizpůsobeno)</li>
    </ul>
    <h3>Podle předchoz&iacute;ho os&iacute;dlen&iacute; &uacute;zem&iacute;</h3>
    <p>Disperze se v&scaron;ak častěji rozděluje podle předchoz&iacute;ho os&iacute;dlen&iacute; &uacute;zem&iacute; druhem, a to na:</p>
    <div className="od-sm">
    <hr />
    <h4><Link to="/biogeografie/disperze/ekologicke-sireni/">Ekologick&eacute; &scaron;&iacute;řen&iacute; druhů</Link></h4>
    <p>Ekologick&aacute; disperse je pojmuta jako proces, při němž se druh &scaron;&iacute;ř&iacute; z m&iacute;sta narozen&iacute; pouze v r&aacute;mci sv&eacute;ho are&aacute;lu, tedy tam, kde už byl. <strong>V samostatn&eacute; kapitole</strong> něco m&aacute;lo o způsobech ekologick&eacute;ho &scaron;&iacute;řen&iacute;.</p>
    <hr />
    <h4><Link to="/biogeografie/disperze/biogeograficke-sireni/">Biogeografick&eacute; &scaron;&iacute;řen&iacute; druhů</Link></h4>
    <p>Biogeografick&aacute; disperse ja pak definov&aacute;na jako ud&aacute;lost, při n&iacute;ž doch&aacute;z&iacute; k trval&eacute;mu os&iacute;dlen&iacute; nov&eacute;ho &uacute;zem&iacute; mimo původn&iacute; are&aacute;l druhu. <strong>V&nbsp;samostatn&eacute; kapitole</strong> jsou rozebr&aacute;ny mechanismy tohoto &scaron;&iacute;řen&iacute;.</p>
    <hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Disperze druhů</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/evolucni/makroevoluce/"><button className="inv">&larr; Makroevoluce</button></Link>
    <Link to="/biogeografie/disperze/ekologicke-sireni/"><button className="inv">Ekologické šíření &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
